<template>
  <div class="account">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
    <div class="account__header d-flex justify-content-between">
      <div>&nbsp;</div>
      <div
        class="account__header__items d-flex align-items-center justify-content-between"
      >
        <div class="account__logo">
          <!-- <LogoBlack />/ -->
        </div>
        <Country />
      </div>
    </div>
    <div class="account__container">
      <div class="account__left">
        <div class="account__left__bg"></div>
      </div>
      <div class="account__right d-flex flex-column">
        <div class="account__form__container vh-100">
          <div class="account__form__wrapper d-flex flex-column">
            <div class="account__head">
              <h5 class="account__head__title">Change Password</h5>
              <p class="account__head__subtitle">
                change your default password
              </p>
            </div>
            <div class="account__body">
              <div class="account__form">
                <form class="form" @submit.prevent="change">
                  <BaseInput
                    label="current password"
                    placeholder="current password"
                    id="oldPassword"
                    :myRef="oldPassword ? null : 'oldPassword'"
                    autofocus
                    required
                    v-model="oldPassword"
                    type="password"
                  >
                  </BaseInput>
                  <BaseInput
                    label="new password"
                    placeholder="Enter new password"
                    id="newPassword1"
                    :myRef="oldPassword ? 'newPassword1' : null"
                    autofocus
                    required
                    v-model="newPassword"
                    type="password"
                  >
                  </BaseInput>
                  <BaseInput
                    label="confirm new password"
                    placeholder="Confirm new password"
                    id="confirmNewPassword"
                    :myRef="null"
                    autofocus
                    required
                    v-model="confirmNewPassword"
                    @input="checkPasswordMatch"
                    type="password"
                    :showRedBorder="confirmPasswordErr"
                  >
                  </BaseInput>
                  <div
                    class="form__item form__action d-flex align-items-center justify-content-between"
                  >
                    <div style="position: relative; z-index: 3">
                      <button
                        type="submit"
                        role="button"
                        href="#"
                        class="button form__button form__button--lg"
                      >
                        {{ process ? "please wait..." : "Change Password" }}
                      </button>
                      <img
                        style="
                          position: absolute;
                          left: -5rem;
                          top: -2.5rem;
                          z-index: -1;
                        "
                        src="/assets/img/dotted-bg.svg"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <!-- <router-link to="/auth/login" class="form__link">
                      Back to Login
                    </router-link> -->
                  </div>
                </form>
              </div>
            </div>
            <div class="account__info mt-auto">
              <div class="account__info__container d-flex align-items-center">
                <div class="account__info__icon">
                  <img src="assets/img/help.svg" alt="" srcset="" />
                </div>
                <div class="">
                  <span class="account__text"
                    >Expert support available to you 24 /7.</span
                  >
                  <a href="mailto:support@bookeet.io" class="account__text account__info__link text--capital">
                    Contact Support
                  </a>
                </div>
                <!-- <div class="account__info__logo">
                  <img src="assets/img/logo-black.svg" alt="" srcset="" />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LogoBlack from "@/components/UI/_base-logoBlack.vue";
import Country from "@/components/_base-country-svg.vue";
import BaseInput from "@/components/Inputs/_base-Input.vue";
import {
  CHANGE_PASSWORD,
  // GET_TWO_FACTOR_AUTH
} from "@/core/services/store/types";
import { required } from "vuelidate/lib/validators";
import CPService from "@/core/services/change.password.service";

export default {
  name: "changePassword",

  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      confirmPasswordErr: false,
      process: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: `Welcome back`,
      },
    };
  },
  validations: {
    oldPassword: {
      required,
    },
    newPassword: {
      required,
    },
  },
  methods: {
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    validate() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.alertError("Your password do not match!");
        this.confirmPasswordErr = true;
        return false;
      }

      this.confirmPasswordErr = false;
      return true;
    },
    checkPasswordMatch(value) {
      if (value !== this.newPassword) return (this.confirmPasswordErr = true);

      return (this.confirmPasswordErr = false);
    },
    async change() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.alertError("current and new password are both required");
        return;
      }
      if (!this.validate()) return;
      this.process = true;
      this.$store
        .dispatch(CHANGE_PASSWORD, {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        })
        // go to which page after successfully login
        .then(() => {
          this.process = false;
          if (CPService.getCPToken().enabled2FA) {
            if (CPService.getCPToken().hasSetup2FA) {
              this.$router.push({ name: "TwoFactorAuth" });
            } else {
              // await this.$store.dispatch(GET_TWO_FACTOR_AUTH);
              this.$router.push({ name: "Configure2Fa" });
            }
          } else {
            CPService.destroyCPToken();
            CPService.destroyCurrentPassword();
            this.alertSuccess(
              "Your password successfully changed, you are being redirect to the dashboard"
            );
            setTimeout(() => {
              const nextPage = this.$router.push({ name: "task" });
              this.$router.go(nextPage);
            }, 2000);
          }
        })
        .catch((e) => {
          this.process = false;
          this.alertError(e.data.message);
        });
    },
  },

  mounted() {
    this.oldPassword = CPService.getCurrentPassword();
  },

  components: {
    // LogoBlack,
    Country,
    BaseInput,
  },
};
</script>
